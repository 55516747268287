<template>
  <div class="row justify-content-center text-center mt-5 mb-5">
    <div class="col-md-6">
      <p>
        Om je rooster te kunnen bekijken hebben we je <code>Yesplan Id</code> nodig.<br/>
        Weet je dit niet? Type dan je naam in het veld en druk dan op de suggestie om het Id over te nemen.
      </p>

      <b-alert variant="danger" :show="isError">Dit Yesplan Id is onjuist. Probeer opnieuw.</b-alert>

      <b-form @submit="onSubmit">
        <b-form-group
            label="Yesplan Id"
            label-for="rosterid"
            description="Vul hier je Yesplan Id in."
        >
          <b-form-input
              id="rosterid"
              v-model="roster"
              type="text"
              required
              @update="getSuggestions" debounce="300" />
        </b-form-group>

        <b-card header="Suggesties" v-if="suggestions.length" class="mb-3">
          <ul class="list-unstyled mt-0 mb-0">
            <li v-for="suggestion in suggestions" :key="suggestion.id"><a href="#" @click.prevent="selectSuggestion(suggestion.id)">{{ suggestion.name }}</a></li>
          </ul>
        </b-card>

        <b-button type="submit" variant="info">Koppelen</b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
export default {
  data() {
    return {
      roster: "",
      isError: false,
      suggestions: []
    }
  },
  computed: {
    ...mapGetters(['rosterId'])
  },
  methods: {
    ...mapActions(['apiGet']),
    onSubmit(evt) {
      evt.preventDefault()
      this.isError = false
      this.apiGet("resource/"+ this.roster).then((result) => {
        this.$store.commit("setRosterId", result.data.name)
        localStorage.setItem("rosterid", result.data.name)
        this.$router.push({name: 'Rooster', params: { date: 'today' }})
      }).catch(() => {
        this.isError = true
      })
    },

    getSuggestions() {
      this.apiGet("resources/type:human name:"+ this.roster).then((result) => {
        this.suggestions = result.data.data
        console.log(this.suggestions)
      }).catch(() => {

      })
    },

    selectSuggestion(id) {
      this.roster = id
      this.suggestions = []
    }
  },
  mounted() {
    if(this.rosterId != null) {
      this.$router.push({name: 'Rooster', params: { date: 'today' }})
    }
  }
}
</script>